/* custom-styles.css */


  .image-gallery-slide img {
    /* スライドの画像のスタイルを上書きする */
    height: 400px;
  }

  .image-gallery-thumbnail {
    /* サムネイル全体のスタイルを上書きする */
    width: 12%;
    padding-bottom:10%;
    object-fit: cover;
    cursor: pointer;
    position: relative;
  }

  .image-gallery-thumbnail img {
    /* サムネイル画像のスタイルを上書きする */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-height: 100%;
  }

  